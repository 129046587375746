import { useState, useRef, useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import * as _ from "lodash";
import moment from "moment";

interface LoaderProps {
    clientInput: any;
    maxPollIntervallInMilliSecs: number | null;
}

const MAX_POLL_INTERVAL_IN_MILLI_SECS : number = 30000;

const Loader = ({ clientInput, maxPollIntervallInMilliSecs } : LoaderProps) => {

  const [remainingTimeInSeconds, setRemainingTimeInSeconds] = useState<number>(0);

  const timerSeconds : number = Math.trunc((!_.isNil(maxPollIntervallInMilliSecs) ? maxPollIntervallInMilliSecs : MAX_POLL_INTERVAL_IN_MILLI_SECS) / 1000);

  const timerProps = {
    isPlaying: true,
    size: 112,
    strokeWidth: 6
  };

  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  });

  return <div id="wrap">
          <div className="wrapper">
            <div id="used-for-swipe-animation" className="main_center">
              <div className="middle" style={{marginBottom:height+'px'}}>
                {clientInput.text && clientInput.text.map((t : any, i : number, _arr : any) => {
                  if (t.text && t.warningWithIcon) {
                    return <div key={i} className="box-light-orange" style={{marginBottom:'15px'}}>
                                <p className="text-orange fs-12 mb-0"><img src="images/ico1.png" className="img-fluid mr-2" alt=""/>{t.text}</p>
                           </div>
                  } else {
                    return <span key={i}/>
                  }
                })}
                <div className="text-center">
                   <div className="img-fluid mb-2" style={{ marginTop:'50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CountdownCircleTimer
                      {...timerProps}
                      key={0}
                      colors={"#FF9900"}
                      trailColor={"#fff"}
                      duration={timerSeconds}
                    >
                      {({ elapsedTime }) => {
                        setRemainingTimeInSeconds(_.isNil(elapsedTime) ? timerSeconds : Math.trunc(timerSeconds - elapsedTime));
                        return (
                          <div className="time-wrapper">
                            <img src="images/flash.svg" alt=''/>
                          </div>
                        );
                      }}
                    </CountdownCircleTimer>
                   </div>
                   <p className="text-orange mb-0 fw-600">{moment().startOf('day').seconds(remainingTimeInSeconds).format('mm:ss')}</p>
                   {clientInput.text && clientInput.text.map((t : any, i : number, _arr : any) => {
                      if (t.text && !t.warningWithIcon) {
                        if (timerSeconds) {
                          t.text = t.text.replace('<:timerSeconds:>', timerSeconds);
                        }
                        
                        if (t.bold) {
                          return <h3 key={i} className="mt-35" style={{marginBottom:'25px'}}>{t.text}</h3>
                        } else {
                          return <p key={i} className="text-gray" style={{marginBottom:'15px'}}>{t.text}</p>
                        }
                      } else {
                        return <span key={i}/>
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
};

export default Loader;
