import { NavigationBar } from "./NavigationBar";
import { loginRequest } from "../authConfig";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

interface InputProps {
    children: any;
    headerless : boolean;
    inner : boolean;
    evseId : string | null;
    sessionId : string | null;
    language : string | null;
    skipAuthorize : boolean;
    env : any;
}

const PageLayout = ({ children, headerless, inner, evseId, sessionId, language, skipAuthorize, env } : InputProps) => {

    const authRequest = {
        ...loginRequest
    };

    const url : string = window.location.protocol + '//' + window.location.host + '/?' + (evseId ? 'evseId=' + evseId + '&' : '') + (sessionId ? 'sessionId=' + sessionId + '&' : '') + (language ? 'language=' + language + '&' : '') + '&headerless=true&inner=true&rand=' + Math.round(Math.random() * 10000000);

    return skipAuthorize ?
            (
                <>
                   {!headerless && <NavigationBar env={env}/>}
                    <br />
                    {inner && children}
                    {!inner &&  <div style={{position: 'absolute', top:'80px', left:0, right:0, display:'flex', flexWrap:'wrap', alignItems:'center', justifyContent:'center'}}>
                                    <iframe title='bot' width='400' height='800' frameBorder="0" src={url} />
                                </div>}
                </>
            )
            :
            (
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                >
                    {!headerless && <NavigationBar env={env}/>}
                    <br />
                    {inner && children}
                    {!inner &&  <div style={{position: 'absolute', top:'80px', left:0, right:0, display:'flex', flexWrap:'wrap', alignItems:'center', justifyContent:'center'}}>
                                    <iframe title='bot' width='400' height='800' frameBorder="0" src={url} />
                                </div>}
                </MsalAuthenticationTemplate>
            )
};

export default PageLayout;
