import { useState, useRef, useEffect } from 'react';

import * as _ from "lodash";

import GoogleMapReact from 'google-map-react';
import { decode } from "@googlemaps/polyline-codec";

const googleApiKey = 'AIzaSyDqZ6iPhtgU0dfLGSDivuFedxN5hHIwmvM';

interface OtherStationProps {
  clientInput: any;
  isApp: boolean;
  languageTextsLocal: Record<string, string>;
}

const OtherStation = ({ clientInput, isApp, languageTextsLocal } : OtherStationProps) => {

  const stationEnBWRef = useRef<any>(clientInput.stationEnBW);
  const stationOtherRef = useRef<any>(clientInput.stationOther);
  const stationEnBWPolyRef = useRef<any>();
  const stationOtherPolyRef = useRef<any>();
  const mapRef = useRef<any>();
  const [enBWSelected, setEnBWSelected] = useState<boolean>(true);

  const mapItems : any = [];

  const MarkerStation = ({ lat : _lat, lng : _lng, maxPower, availablity, stationEnBW } : 
    { lat : number, lng: number, maxPower: string, availablity: string, stationEnBW: boolean }) => {
    return (<>
            <div onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              if (enBWSelected !== stationEnBW) {
                if (enBWSelected) {
                  stationEnBWPolyRef.current.setMap(null);
                  stationOtherPolyRef.current.setMap(mapRef.current);
                } else {
                  stationEnBWPolyRef.current.setMap(mapRef.current);
                  stationOtherPolyRef.current.setMap(null);
                }
                setEnBWSelected(stationEnBW);
              }
            }}>
            <div className="pin">
              <div className="pin-availability"></div>
            </div>
            <div className="pin-power">
              <div>{maxPower}</div>
            </div>
            <div className="pin-availability">
              <div>{availablity}</div>
            </div>
          </div>
          </>);
  }

  const MarkerStart = ({ lat : _lat, lng : _lng} : 
      { lat : number, lng: number }) => {
    return (<div className="pulse"></div>);
  }

  const createMapOptions = (_maps : any) => {
    return {
      panControl: true,
      mapTypeControl: false,
      scrollwheel: false,
      zoomControl: true,
      fullscreenControl: false
    }
  }

  const getMapContent = () => {

    const content = [];

    let path = decode(stationEnBWRef.current.polygon);
    content.push(<MarkerStart key={0} lat={clientInput.currentStationLatLon[0]} lng={clientInput.currentStationLatLon[1]}/>);
    content.push(<MarkerStation key={stationEnBWRef.current.stationId} lat={path[path.length-1][0]} lng={path[path.length-1][1]} maxPower={stationEnBWRef.current.maxPowerInKw + ' kW'} availablity={stationEnBWRef.current.availableChargePoints + '/' + stationEnBWRef.current.numberOfChargePoints} stationEnBW={true}/>);
    mapItems.push.apply(mapItems, path);

    if (stationOtherRef.current) {
      path = decode(stationOtherRef.current.polygon);
      content.push(<MarkerStation key={stationOtherRef.current.stationId} lat={path[path.length-1][0]} lng={path[path.length-1][1]} maxPower={stationOtherRef.current.maxPowerInKw + ' kW'} availablity={stationOtherRef.current.availableChargePoints + '/' + stationOtherRef.current.numberOfChargePoints} stationEnBW={false}/>);
      mapItems.push.apply(mapItems, path);
    }

    return content;
  }

  const formatDistanceInMeters = (distanceInMeters : number) => {
    if (distanceInMeters < 1000) {
      return distanceInMeters + ' m';
    } else {
      return (Math.round(distanceInMeters/100)/10) + ' km';
    }
  }

  const getMapBounds = (_map : any, maps : any) => {
    const bounds = new maps.LatLngBounds();

    mapItems.forEach((mapItem : any) => {
      bounds.extend(new maps.LatLng(
        mapItem[0],
        mapItem[1]
      ));
    });
    return bounds;
  };

  const lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 4,
  };

  const handleApiLoaded = (map : any, maps : any) => {
    const bounds = getMapBounds(map, maps);
    map.fitBounds(bounds);
    mapRef.current = map;

    if (stationEnBWRef.current) {
      stationEnBWPolyRef.current = new maps.Polyline({
        path: decode(stationEnBWRef.current.polygon).map((i) => { return { lat : i[0], lng : i[1] }}),
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
        map : mapRef.current
      });
    }
    if (stationOtherRef.current) {
      stationOtherPolyRef.current = new maps.Polyline({
        path: decode(stationOtherRef.current.polygon).map((i) => { return { lat : i[0], lng : i[1] }}),
        strokeOpacity: 0,
        icons: [
          {
            icon: lineSymbol,
            offset: "0",
            repeat: "20px",
          },
        ],
      });
    }

    maps.event.addListener(_, "click", (e : any) => {     
      e.preventDefault();
    });
  };

  const station = () => {
    return enBWSelected ? stationEnBWRef.current : stationOtherRef.current;
  }

  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  });

  return <div id="wrap" style={{marginTop:'-53px'}}>
          <div className="wrapper" style={{marginLeft:'0', marginRight:'0', width:'100%'}}>
            <div id="used-for-swipe-animation" className="main_center">
              <div className="middle" style={{marginBottom:height+'px'}}>
                <div className="map">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: googleApiKey }}
                    defaultCenter={{ lat: clientInput.currentStationLatLon[0], lng: clientInput.currentStationLatLon[1] }}
                    defaultZoom={13}
                    options={createMapOptions}
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    yesIWantToUseGoogleMapApiInternals
                  >
                    {getMapContent()}
                  </GoogleMapReact>
                </div>

                <div className="map-details py-3">
                  {enBWSelected && stationEnBWRef.current.maxPowerInKw >= 100 &&
                    <div className="badge-mini-blue d-inline-flex align-items-center pl-1 py-1">
                      <span className="icon-outline-white mr-1"><img src="images/ico-mesg.svg" className="img-fluid" width="10" alt=""/></span>{languageTextsLocal['enbwFastCharger']}
                    </div>
                  }
                  <p className="text-gray mb-0 fs-14">{languageTextsLocal['operator']}: {station().operator}</p>
                  <h4 className="fw-600 mb-0">{station().address}</h4>
                  <p className="text-gray mb-0 fs-14"><span className="text-green"> {station().availableChargePoints} </span>{languageTextsLocal['of']} {station().numberOfChargePoints} <span className="text-green">{languageTextsLocal['available'].toLowerCase()} </span>• {formatDistanceInMeters(station().distanceInMeters)}</p>
                  <p className="text-gray fs-14 mb-0">{station().plugTypeNames.join(', ')} <span className="float-right">max. <span className="text-orange"> {station().maxPowerInKw + ' kW'}</span></span></p>           
                </div>
              </div>

              <div className="middle-bottom" ref={ref} style={{paddingLeft:'30px', paddingRight:'30px'}}>     
                <div style={{height:'30px', background:'linear-gradient(to top, white, transparent)'}}></div>

                <div style={{backgroundColor:'#fff'}}>

                  {clientInput.inputs && clientInput.inputs.filter((t : any) => { return !t.client || !t.client.app || !t.client.skip; }).map((t : any, i : number, _arr : any) => {
                    if (isApp) { 
                      return <button key={i} className="btn btn-start w-100" onClick={() => { 
                        window.location.replace(window.location.href.replace(/#navigateTo=[0-9]+.[0-9]+,[0-9]+.[0-9]+/g, '') + '#navigateTo=' + station().lat + ',' + station().lon);
                      }}>{t.inputText}</button>
                    } else {
                      return <button key={i} className="btn btn-start w-100" data-toggle="modal" data-target="#modalPBottom">{t.inputText}</button>
                    }
                  })}

                  <div className="modal fade modal-p-bottom" id="modalPBottom">
                    <div className="modal-dialog" style={{paddingBottom:'120px'}}>
                      <div className="modal-content">
                        <div className="modal-body text-center">
                          <p className="fs-13 mb-0">{languageTextsLocal['applicationSelect']}</p>
                          <hr/>
                          <p className="mb-0 text-orange"><a href={station().naviLink} target="_blank" rel="noreferrer">Apple Maps</a></p>
                          <hr/>
                          <p className="mb-0 text-orange"><a href={station().naviLink} target="_blank" rel="noreferrer">Google Maps</a></p>
                          <hr/>
                          <p className="mb-0 text-danger" data-toggle="modal" data-target="#modalPBottom" style={{cursor:'pointer'}}>{languageTextsLocal['cancel']}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{height:'30px', background:' linear-gradient(to bottom, white, transparent)'}}></div>
              </div>
            </div>
          </div>
        </div>

};

export default OtherStation;
