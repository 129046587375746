import { useState, forwardRef, useImperativeHandle } from 'react';
import { Box, Button, InputLabel, MenuItem, FormControl, TextField, Modal, Select } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface CancelFormProps {
    handler: Function;
};

export type CancelFormHandle = {
  open: () => void,
};

const CancelForm = forwardRef<CancelFormHandle, CancelFormProps>(({ handler }: CancelFormProps, ref) => {

  const [opened, setOpened] = useState<boolean>(false);
  const handleClose = (type : boolean) => {
    setOpened(false);
    setCancelType(null);
    setCancelReason(null);
    if (!type) {
      return;
    }
    if (type && !cancelType) {
      alert('Abbruchgrund muss angegeben werden');
      return;
    }
    handler(cancelType, cancelReason);
  };

  const open = () => {
    if (!opened) {
      setOpened(true);
    }
  };

  const [cancelType, setCancelType] = useState<string | null>(null);
  const handleChangeCancelType = (event: any) => {
    setCancelType(event.target.value as string);
  };

  const [cancelReason, setCancelReason] = useState<string | null>(null);
  const handleChangeCancelReason = (event: any) => {
    setCancelReason(event.target.value as string);
  };

  useImperativeHandle(ref, () => {
    return {
      open: open
    };
  });

  return (
    <Modal
      open={opened}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Abbruchgrund</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={cancelType}
            label="Abbruchgrund"
            onChange={handleChangeCancelType}
          >
            <MenuItem value={'Problem hat sich von selbst gelöst'}>Problem hat sich von selbst gelöst</MenuItem>
            <MenuItem value={'Anruf war keine akute Störungsmeldung'}>Anruf war keine akute Störungsmeldung</MenuItem>
            <MenuItem value={'Kunde hat kein Interesse an der abschließenden Problemlösung'}>Kunde hat kein Interesse an der abschließenden Problemlösung</MenuItem>
            <MenuItem value={'Anwenderfehler'}>Anwenderfehler</MenuItem>
            <MenuItem value={'Test'}>Test</MenuItem>
          </Select>
        </FormControl>
        <br/><br/>
        <FormControl fullWidth>
          <TextField
            id="filled-multiline-flexible"
            label="Zusätzliches Feedback hinterlassen (optional)"
            multiline
            rows={10}
            value={cancelReason}
            onChange={handleChangeCancelReason}
            variant="filled"
          />
        </FormControl>
        <br/><br/>
        <Box textAlign='center'>
          <Box m={2}>
            <Button style={{verticalAlign: "middle"}} variant="contained" onClick={() => { 
              handleClose(false)     
            }}>Schließen</Button>
          </Box>
          <Box>
            <Button style={{verticalAlign: "middle"}} variant="contained" color={'primary'} onClick={() => { 
              handleClose(true)     
            }}>Feedback absenden</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
});

export default CancelForm;
