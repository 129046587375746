import React from 'react';
import {createRoot} from 'react-dom/client';
import { RecoilRoot } from 'recoil';

import App from "./App";
import reportWebVitals from './reportWebVitals';

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

const ENV_CONFIG : any = (window as any)._env_;
const AUTH_CLIENT_ID : string = ENV_CONFIG?.AUTH_CLIENT_ID ?? '5622f95f-b18e-4bb7-b89d-c4d444cc917b';
const AUTH_AUTHORITY : string = ENV_CONFIG?.AUTH_AUTHORITY ?? 'https://login.microsoftonline.com/9203a564-04f2-4fcf-b32a-a98d8b66a5fe';
const AUTH_REDIRECT_URI : string = ENV_CONFIG?.AUTH_REDIRECT_URI ?? 'http://localhost:3000';

msalConfig.auth.clientId = AUTH_CLIENT_ID;
msalConfig.auth.authority = AUTH_AUTHORITY;
msalConfig.auth.redirectUri = AUTH_REDIRECT_URI;

export const msalInstance : PublicClientApplication = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

const rootElement = document.getElementById('root');
if(rootElement === null) {
  throw new Error('Root element not found.');
}
const root = createRoot(rootElement);
root.render(
  <RecoilRoot>
    <React.StrictMode>
      <App instance={msalInstance} env={ENV_CONFIG}/>
    </React.StrictMode>
  </RecoilRoot>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
