import { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';

import { useRecoilValue } from 'recoil';
import { disabledAtom } from '../Bot';

import * as _ from "lodash";

interface InputProps {
  clientInput: any;
  isFinal: boolean;
  config?: any;
  salutation?: null | string;
  stepInstanceId: null | string;
  isApp: boolean;
  languageTextsLocal: Record<string, string>;
  handler: Function;
}

const FAQ_LINK : string = 'https://support-faqbot.emob-enbw.com/';

const Input = ({ clientInput, isFinal, config, salutation, stepInstanceId, isApp, languageTextsLocal, handler } : InputProps) => {

  const disabled = useRecoilValue<boolean>(disabledAtom);
  const [precondition, setPrecondition] = useState<string | null>(null);
  const [preconditionChecked, setPreconditionChecked] = useState<boolean>(false);

  const [textinput, setTextinput] = useState<any | null>(clientInput.lastTextinput ? clientInput.lastTextinput : '');

  const handleCheckClick = () => {
    setPreconditionChecked(!preconditionChecked);
  }

  useEffect(() => {
    if (clientInput.inputs) {
      for (const t of clientInput.inputs) {
        if (t.precondition) {
          setPrecondition(t.precondition);
          break;
        }
      };
    }
  }, [clientInput.inputs]);

  const images : any = {
    'wifi' : 'images/wifi.jpg',
    'ok' : 'images/ok.svg',
    'map' : 'images/map.svg',
    'error' : 'images/error.svg',
    'error_not_enbw' : 'images/error_not_enbw.jpg',
    'car-plug' : 'images/car-pump.svg',
    'ccs' : 'images/cable.svg',
    'chademo' : 'images/cable1.svg',
    'type2' : 'images/cable2.svg',
    'info' : 'images/info.svg'
  };

  const cableIcon = (plugType : string) => {
    switch (plugType.toLowerCase()) {
      case 'ccs':
        return images['ccs'];
      case 'chademo':
        return images['chademo'];
      case 'type 2':
        return images['type2'];
      default: // todo replace with basic plug icon
        return images['CCS'];
    }
  };

  const statusColor = (status : string) => {
    switch (status) {
      case 'AVAILABLE':
        return "text-green";
      default:
        return "text-red";
    }
  };

  const processClick = (t : any) => {

    if (!isFinal && !disabled) {
      handler(stepInstanceId, { 'inputValue' : t.inputValue, 'textinput' : textinput });

      // temporary fix for one case where call support is not final state
      if (isApp && t.client?.app?.call) {
        window.location.replace(window.location.href.replace(/#call=\+[0-9]+/g, '') + '#call=' + t.client?.app?.call);
      }
      if (!_.isNil(t.client?.app?.refresh)) {
        if (isApp) {
          window.location.replace(window.location.href.replace(/#close=true+/g, '') + '#close=true');
        } else {
          setTimeout(() => { alert(languageTextsLocal['closeBrowser']); }, t.client.app.refresh * 1000);
        }
      }

    } else if (!isApp && !_.isNil(t.client?.app?.refresh)) {
      // eslint-disable-next-line no-restricted-globals
      setTimeout(() => { alert(languageTextsLocal['closeBrowser']); }, t.client.app.refresh * 1000);

    } else if (isApp && t.client?.app?.call) {
      window.location.replace(window.location.href.replace(/#call=\+[0-9]+/g, '') + '#call=' + t.client?.app?.call);

    } else if (isApp) {
      window.location.replace(window.location.href.replace(/#close=true+/g, '') + '#close=true');
    }
  };

  const isDisableable = (t : any) => {
    return !t.client?.app?.call && _.isNil(t.client?.app?.refresh);
  };

  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  });

	return <div id="wrap">
          <div className="wrapper">
            <div id="used-for-swipe-animation" className="main_center">
              <div className="middle" style={{marginBottom:height+'px'}}>
                {clientInput.text && clientInput.text.map((t : any, i : number) => {
                  if (t.text) {
                    //if (salutation) {
                    t.text = t.text.replace('<:salutation:>', salutation ? ' ' + salutation : '');
                    //}
                    if (t.warningWithIcon) {
                      return <div key={i} className="box-light-orange" style={{marginBottom:'15px'}}>
                                <p className="text-orange fs-12 mb-0"><img src="images/ico1.png" className="img-fluid mr-2" alt=""/>{t.text}</p>
                             </div>
                    } else if (t.warningNoIcon) {
                      return <div key={i} className="box-light-orange my-48">
                                <p className="text-orange fs-13 mb-0">{t.text}</p>
                             </div>
                    } else if (t.bold) {
                      return <h2 key={i} className="main-title" style={{marginBottom:'25px'}}>{t.text}</h2> /* class main_title with mb 15px*/
                    } else if (t.box) {
                      return (
                        <div key={i} className="box-gray border text-center code-number" style={{marginBottom:'15px!important'}}>
                           <p className="mb-0">{t.text}</p>
                        </div>
                      )
                    } else {
                      return <p key={i} className="text-gray" style={{marginBottom:'15px'}}>{t.text}</p>
                    }
                  } else if (t.image) {
                    return <img key={i} src={images[t.image]} className="img-fluid d-block mx-auto" style={{marginTop:'35px', marginBottom:'35px'}} alt=""/>;
                  } else {
                    return <span key={i}/>;
                  }
                })}
              </div>

              <div className="middle-bottom" ref={ref}>
                <div className="gradient-top" style={{height:'30px'}}></div>

                <div style={{backgroundColor:'#fff'}}>
                  {precondition &&
                    <div className="box-shadow" onClick={handleCheckClick}>
                      <div className="chiller_cb">
                        <input type="checkbox" readOnly checked={preconditionChecked}/>
                        <label /*for="myCheckbox2"*/>{precondition}</label>
                        <span></span>
                      </div>
                    </div>
                  }

                  {clientInput.inputs && handler && clientInput.inputs.filter((t : any) => { return !t.client?.app?.skip; }).map((t : any, i : number, arr : any) => {

                    if (t.client?.app?.index) {
                      return <div key={i} className={"box-gray p-4 " + (arr.length-1 === i ? 'mb-3' : 'mb-3')} style={{cursor:'pointer', opacity: (disabled && isDisableable(t)) || (!!precondition && precondition === t.precondition && !preconditionChecked) ? '0.3' : '1'}} onClick={() => {
                                processClick(t);
                              }}>
                              <div className="media align-items-center">
                                 <div className="media-body">
                                    <p className="mb-0">{t.inputText}</p>
                                 </div>
                                 <img src="images/arrow-blue.svg" className="img-fluid d-block ml-auto" alt=''/>
                              </div>
                             </div>

                    } else if (t.client?.app?.chargePoint) {
                      return <div key={i} className="d-flex justify-content-between cable" style={{cursor:'pointer', opacity: (disabled && isDisableable(t)) || (!!precondition && precondition === t.precondition && !preconditionChecked) ? '0.3' : '1'}} onClick={() => {
                                processClick(t);
                              }}>
                              <div className="left-img px-0">
                                <img src={cableIcon(t.plugType)} className="img-fluid" alt=""/>
                              </div>
                              <div className="cable-txt">
                                 <h6 className="fw-700 mb-0">{t.inputText}</h6>
                                 <p className="my-1">{t.plugTypeName + ' • max. '}<span className="text-orange">{t.maxPower + ' kW'}</span></p>
                                 <p>{t.priceInfo}</p>
                              </div>
                              <div className="cable-next text-right">
                                 <p className={statusColor(t.status)}>{t.statusText}</p>
                                 <button className="text-gray arrow-r" style={{background:'none', border:0, marginTop:'20px'}}><i className="fa fa-angle-right" aria-hidden="true"></i></button>
                              </div>
                             </div>

                    } else if (t.client?.app?.textinput) {
                      return <div key={i}>
                                <TextField
                                  autoFocus={true}
                                  value={textinput}
                                  variant="outlined"
                                  color="primary"
                                  style={{'width':'100%', 'marginBottom':'40px'}}
                                  onChange= {(event: any) => {
                                    setTextinput(event.target.value);
                                  }}
                                  inputProps={{ style: { fontFamily: 'SFProText', fontSize: 16, fontWeight: 400, lineHeight: '24px', opacity: (disabled && isDisableable(t)) ? '0.3' : '1' }}}
                                  disabled={(disabled && isDisableable(t))} />
                              </div>

                    } else if (t.client?.app?.outline) {
                      return <button key={i} disabled={(disabled && isDisableable(t)) || (!!precondition && precondition === t.precondition && !preconditionChecked)} style={{opacity: (disabled && isDisableable(t)) || (!!precondition && precondition === t.precondition && !preconditionChecked) ? '0.3' : '1'}} className={"btn btn-start-outline bg-white w-100 " + (arr.length-1 ===i ? ( config?.client?.app?.showSupport ? 'mb-30' : 'mb-0') : '')} onClick={() => {
                        processClick(t);
                      }}>{t.inputText}</button>

                    } else {
                      return <button key={i} disabled={(disabled && isDisableable(t)) || (!!precondition && precondition === t.precondition && !preconditionChecked)} style={{opacity: (disabled && isDisableable(t)) || (!!precondition && precondition === t.precondition && !preconditionChecked) ? '0.3' : '1'}} className={"btn btn-start w-100 " + (arr.length-1 ===i ? ( config?.client?.app?.showSupport ? 'mb-30' : 'mb-0') : '')} onClick={() => {
                        processClick(t);
                      }}>{t.inputText}</button>
                    }
                  })}

                  {config?.client?.app?.showSupport &&
                    <div className="box-outline-dark text-center py-3 rounded-bottom-0">
                      <p className="text-gray fs-12 mb-0">{languageTextsLocal['otherQuestion']}</p>
                      {isApp ?
                        <a className="text-orange btn-txt fs-12" onClick={() => {
                          window.location.replace(window.location.href.replace(/#faq=[0-9]+/g, '') + '#faq=' + Date.now());
                        }}>{languageTextsLocal['faq']}</a>
                        :
                        <a href={FAQ_LINK} target="_target" className="text-orange btn-txt fs-12">{languageTextsLocal['faq']}</a>
                      }
                    </div>
                  }
                </div>
                <div className="gradient-bottom" style={{height:'30px'}}></div>
              </div>
            </div>
          </div>
       	</div>
};

export default Input;
