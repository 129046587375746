/* eslint-disable */

import { useState, useRef, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Box, Button } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import * as _ from "lodash";

import Input from './components/input';
import Loader from './components/loader';
import CarSelector from './components/carSelector';
import OtherStation from './components/otherStation';
import CancelForm, { CancelFormHandle } from './components/cancelForm';

import { useMsal, useAccount } from "@azure/msal-react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './animate.min.css';
import './bot.css';

import { getToken } from './App';

import {
  atom,
  useSetRecoilState,
} from 'recoil';

const MIN_VIEW_TIME_IN_MILLI_SECS = 5000;
const POLL_INTERVAL_IN_MILLI_SECS = 1000;

const FETCH_TIMEOUT = 5000;
const MAX_POLL_INTERVAL_IN_MILLI_SECS_SERVER_NOT_AVAILABLE = 10000;

const ENBW_SUPPORT_HOTLINE : string = '+497212015033'; // TODO: get from backend instead

function BackendError(this : any, message : string | null = null) {
    this.name = "BackendError";
    this.message = message;
};
BackendError.prototype = new Error();

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

const languageTextsLocalDe : Record<string, string> = {
  'technicalError' : 'Leider ist ein technischer Fehler aufgetreten',
  'contactSupport' : 'Bitte wenden Sie sich an unsere Servicehotline!',  
  'allOk' : 'EnBW Servicehotline anrufen',
  'closeBrowser' : 'Bitte Browser-Fenster schließen und neue Bot Session aus dem CPO-Run starten.',
  'nearByStation' : 'Nächste Ladestation',
  'selectCar' : 'E-Auto wählen',
  'noStationFound' : 'Leider wurde keine passende Ladestation gefunden.',
  'enbwFastCharger' : 'EnBW Schnellladestation',
  'chargingStation' : 'Ladestation',
  'available' : 'Verfügbar',
  'applicationSelect' : 'Anwendung auswählen',
  'cancel' : 'Abbrechen',
  'brand' : 'Marke',
  'model' : 'Modell',
  'otherQuestion' : 'Andere Frage zum Ladevorgang?',
  'faq' : 'Hier geht’s zu den FAQs',
  'of' : 'von',
  'capacity' : 'Kapazität',
  'botNotAvailable' : 'Die Soforthilfe ist momentan leider nicht verfügbar',
  'operator' : 'Betreiber',
  'carSelector1' : 'Wählen Sie bitte Ihr Fahrzeug aus.',
  'carSelector2' : 'Im nächsten Schritt zeigen wir Ihnen, wie Sie die Entriegelung an Ihrem Fahrzeug vornehmen. Hierfür müssen wir zunächst wissen, welches Fahrzeug Sie fahren.',
  'carSelector3' : 'Sollten Sie Ihr Fahrzeugmodell in der Auswahl nicht finden können, wenden Sie sich bitte an unsere Servicehotline. Nutzen Sie hierfür einfach den Telefonhörer oben rechts.',
};

const languageTextsLocalEn : Record<string, string> = {
  'technicalError' : 'Unfortunately a technical error has occurred',
  'contactSupport' : 'Please contact our support hotline!',  
  'allOk' : 'Call EnBW support hotline',
  'closeBrowser' : 'Please close browser window and start new bot session from CPO run.',
  'nearByStation' : 'Next charging station',
  'selectCar' : 'Select your car',
  'noStationFound' : 'Unfortunately no suitable charging station was found nearby.',
  'enbwFastCharger' : 'EnBW fast charging station',
  'chargingStation' : 'Charging station',
  'available' : 'Available',
  'applicationSelect' : 'Select application',
  'cancel' : 'Cancel',
  'brand' : 'Brand',
  'model' : 'Model',
  'otherQuestion' : 'Other question about the charging process?',
  'faq' : 'Click here for the FAQs',
  'of' : 'of',
  'capacity' : 'Capacity',
  'botNotAvailable' : 'Unfortunately, the instant help is not available at the moment',
  'operator' : 'Operator',
  'carSelector1' : 'Please select your vehicle.',
  'carSelector2' : 'In the next step, we will show you how to unlock your vehicle. For this, we first need to know which vehicle you drive.',
  'carSelector3' : 'If you can\'t find your vehicle in the selection, please contact our service hotline. Simply use the telephone receiver at the top right.',
};

export const disabledAtom = atom<boolean>({
  key: 'disabled',
  default: false
});

interface InputProps {
    env : any;
    accessToken : string | null;
    installationIdentifier : string | null;
    advertisingIdentifier : string | null;
    identifierForVendor : string | null;
    platform : string | null;
    appVersion : string | null;
    osVersion : string | null;
    versionCode : string | null;
    deviceModel : string | null;
    messagingToken : string | null;
    apiKey : string | null;
    evseId : string | null;
    sessionId : string | null;
    stepInstanceId : string | null;
    language : string | null;    
    carId: string | null;
    showHeader : boolean;
    isApp: boolean;
};

const Bot = ({ 
    env,
    accessToken : accessTokenProvided, 
    installationIdentifier : installationIdentifierProvided,
    advertisingIdentifier : advertisingIdentifierProvided,
    identifierForVendor : identifierForVendorProvided,
    platform : platformProvided,
    appVersion : appVersionProvided,
    osVersion : osVersionProvided,
    versionCode : versionCodeProvided,
    deviceModel : deviceModelProvided,
    messagingToken : messagingTokenProvided,
    apiKey : apiKeyProvided,
    evseId : evseIdProvided, 
    sessionId : sessionIdProvided, 
    stepInstanceId : stepInstanceIdProvided,
    carId : carIdProvided, 
    language : languageProvided, 
    showHeader : showHeaderProvided,
    isApp,
  } : InputProps) => {

  const SERVER_ENDPOINT : string = env?.API_URL ?? 'http://localhost:8080/bot/session';
  const DISABLED : boolean = isApp && env?.DISABLED?.toLowerCase() === 'true';

  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [accessToken, setAccessToken] = useState<string | null>(accessTokenProvided);

  const installationIdentifier = useRef<string | null>(installationIdentifierProvided);
  const advertisingIdentifier = useRef<string | null>(advertisingIdentifierProvided);
  const identifierForVendor = useRef<string | null>(identifierForVendorProvided);
  const platform = useRef<string | null>(platformProvided);
  const appVersion = useRef<string | null>(appVersionProvided);
  const osVersion = useRef<string | null>(osVersionProvided);
  const versionCode = useRef<string | null>(versionCodeProvided);
  const deviceModel = useRef<string | null>(deviceModelProvided);
  const messagingToken = useRef<string | null>(messagingTokenProvided);
  const apiKey = useRef<string | null>(apiKeyProvided);

  const sessionId = useRef<string | null>(sessionIdProvided);
  const stepInstanceId = useRef<string | null>(stepInstanceIdProvided);
  const evseId = useRef<string | null>(evseIdProvided);
  const carId = useRef<string | null>(carIdProvided);

  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const lastNonVisibleStepInstanceId = useRef<string | null>(null);
  const waitingForClientOutput = useRef<boolean>(false);  
  const setDisabled = useSetRecoilState<boolean>(disabledAtom);
  const isFinal = useRef<boolean>(false);
  const timeoutRef = useRef<any | null>(null);
  const abortControllerRef = useRef<any | null>(null);
  const [activeView, setActiveView] = useState<any | null>(null);
  const [currentUUID, setCurrentUUID] = useState<string>();

  let userLang = languageProvided ?? navigator.language;
  userLang = ['de', 'en'].includes(userLang) ? userLang : 'de';
  const [language, setLanguage] = useState<string>(userLang);
  const languageSelected = useRef(userLang);
  const languageTextsLocal = useRef(languageTextsLocalDe);

  const showHeaderRef = useRef<boolean>(showHeaderProvided);
  const showCancelModalRef = useRef<CancelFormHandle>(null);

  // time measurement
  const poll_timestamp_server_start = useRef<number | null>(null);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (language === 'de') {
      languageTextsLocal.current = languageTextsLocalDe;
    } else {
      languageTextsLocal.current = languageTextsLocalEn;
    }
  }, [language]);

  useEffect(() => {
    if (account && inProgress === "none") {
      getToken(instance, account, ["User.Read"]).then((accessToken : string) => {
        setAccessToken(accessToken)
      }).catch((error) => console.log(error));
    }
  }, [account, inProgress, instance]);

  useEffect(() => { 

    if (accessToken) {
      if (DISABLED) {    
        showServiceUnavailable();
      } else {
        fetchServer();
      }
    } else if (!accounts || accounts.length === 0) {
      finalizeWithError();
    }
  }, [accessToken]);

  const clientOutputHandler = (clientOutputStepInstanceId : string, clientOutput : any) => {
    if (isFinal.current || !clientOutputStepInstanceId || clientOutputStepInstanceId !== stepInstanceId.current) {
      return;
    }
    setDisabled(true);
    fetchServer(clientOutputStepInstanceId, clientOutput);
  };

  const showServiceUnavailable = () => {
    if (isFinal.current) {
      return;
    }
    isFinal.current = true;
    setDisabled(true);

    cancelCommunication();

    setIsInitializing(false);
    
    setActiveView(<Input 
      key={uuidv4()} 
      clientInput={{
        text: [
          { text : languageTextsLocal.current['botNotAvailable'], bold : true },
          { text : languageTextsLocal.current['contactSupport'] },
          { image : 'info' }
        ],
        inputs: [
          {
            inputText: languageTextsLocal.current['allOk'],
            client : {
              app : {
                refresh : 1,
                call : ENBW_SUPPORT_HOTLINE,
              }
            }
          }
        ]
      }} 
      isFinal={true} 
      config={{
        client : {
          app : {
            showSupport : true
          } 
        }
      }} 
      salutation={''} 
      stepInstanceId={uuidv4()} 
      isApp={isApp}
      languageTextsLocal={languageTextsLocal.current}
      handler={clientOutputHandler}/>);

    setCurrentUUID(uuidv4());
  };

  const finalizeWithError = () => {
    if (isFinal.current) {
      return;
    }
    isFinal.current = true;
    setDisabled(true);

    cancelCommunication();

    setIsInitializing(false);

    setActiveView(<Input 
      key={uuidv4()} 
      clientInput={{
        text: [
          { text : languageTextsLocal.current['technicalError'], bold : true },
          { text : languageTextsLocal.current['contactSupport'] },
          { image : 'info' }
        ],
        inputs: [
          {
            inputText: languageTextsLocal.current['allOk'],
            client : {
              app : {
                refresh : 1,
                call : ENBW_SUPPORT_HOTLINE,
              }
            }
          }
        ]
      }} 
      isFinal={true} 
      config={{
        client : {
          app : {
            showSupport : true
          } 
        }
      }} 
      salutation={''} 
      stepInstanceId={uuidv4()} 
      isApp={isApp}
      languageTextsLocal={languageTextsLocal.current}
      handler={clientOutputHandler}/>);
    setCurrentUUID(uuidv4());
  };

  const finalizeWithSuccess = () => {
    if (isFinal.current) {
      return;
    }
    isFinal.current = true;
    setDisabled(true);

    cancelCommunication();
  };

  const cancelCommunication = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const handleLanguage = (
    _event: React.MouseEvent<HTMLElement>,
    newLanguage: string) => {
    cancelCommunication();

    isFinal.current = false;

    setLanguage(newLanguage);
    languageSelected.current = newLanguage;

    stepInstanceId.current = lastNonVisibleStepInstanceId.current;
    waitingForClientOutput.current = false;

    fetchServer();
  };

  const cancelModalHandler = (cancelType : string, cancelReason : string) => {
    if (cancelType && stepInstanceId.current) {
      clientOutputHandler(stepInstanceId.current, { 'inputValue' : 'cancelSession', 'cancelType' : cancelType, 'cancelReason' : cancelReason });
      setTimeout(() => { alert(languageTextsLocal.current['closeBrowser']); }, 1000);
    }
  };

  const fetchServer = (clientOutputStepInstanceId? : string, clientOutput? : any) => {

    if (DISABLED) {
      return;
    }

    if (isFinal.current) {
      return;
    }

    if (!accessToken) {
      return;
    }

    let requestOptions : any;
    let salutation : string | null;
    
    if (!accessToken) {
      finalizeWithError();
      return;
    
    } else if (!sessionId.current) {

      requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key' : accessToken },
        body: JSON.stringify({ 
          data : { 
            evseId : evseId.current,
            carId : carId.current, 
            installationIdentifier : installationIdentifier.current,
            advertisingIdentifier : advertisingIdentifier.current,
            identifierForVendor : identifierForVendor.current,
            platform : platform.current,
            appVersion : appVersion.current,
            osVersion : osVersion.current,
            versionCode : versionCode.current,
            deviceModel : deviceModel.current,
            messagingToken : messagingToken.current,
            apiKey : apiKey.current,
          },
          language : languageSelected.current
        })
      }

    } else if (
      clientOutputStepInstanceId && 
      waitingForClientOutput.current) {

      waitingForClientOutput.current = false; // should move after fetch only if fetch is ok

      requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key' : accessToken },
        body: JSON.stringify({ 
          sessionId : sessionId.current,
          clientOutputStepInstanceId : clientOutputStepInstanceId,
          clientOutput : clientOutput,
          stepInstanceIdAfterStepInstanceId : stepInstanceId.current,
          language : languageSelected.current
        })
      }

    } else {
      let body : any = { 
        sessionId : sessionId.current,
        language : languageSelected.current
      }

      if (stepInstanceId.current) {
        body.stepInstanceIdAfterStepInstanceId = stepInstanceId.current;
      }

      requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key' : accessToken },
        body: JSON.stringify(body)
      }
    }

    // https://react-typescript-cheatsheet.netlify.app/docs/basic/useful-hooks
    //showLoaderWithTime(MAX_POLL_INTERVAL_IN_MILLI_SECS);

    // setup fetch timeout
    abortControllerRef.current = new AbortController();
    const fetchTimeoutId = setTimeout(() => abortControllerRef.current.abort(), FETCH_TIMEOUT);
    requestOptions.signal = abortControllerRef.current.signal;

    fetch(SERVER_ENDPOINT, requestOptions)
      .then(res => {
          // 200-299
          if (res.ok) {
            return res.json();
          } else if (res.status === 503) {
            showServiceUnavailable();
            return;
          // !200-299
          } else {
            throw new (BackendError as any)();
          }
        }
      )
      .then((data) => {
        clearTimeout(fetchTimeoutId);

        if (isFinal.current) {
          return;
        }

        // reset
        poll_timestamp_server_start.current = null;

        // error message
        if (data && data.error) {
          finalizeWithError();
          return;
        }

        // new session
        if (!sessionId.current && data.sessionId) {

          setIsInitializing(false);

          sessionId.current = data.sessionId;

          timeoutRef.current = setTimeout(() => {
            fetchServer();
          }, data.config && data.config.pollIntervalInMilliSecs ? data.config.pollIntervalInMilliSecs : POLL_INTERVAL_IN_MILLI_SECS);
        }

        // waiting for first stepInstanceId
        else if (sessionId.current && data.stepInstanceId) {

          setIsInitializing(false);

          // no update, stepInstance already known and rendered
          if (stepInstanceId.current && stepInstanceId.current === data.stepInstanceId) {
            if (data.isSessionFinal) {
              finalizeWithSuccess();
              return;
            }
            timeoutRef.current = setTimeout(() => {
              fetchServer();
            }, data.config && data.config.pollIntervalInMilliSecs ? data.config.pollIntervalInMilliSecs : POLL_INTERVAL_IN_MILLI_SECS);
            return;
          }

          // new stepInstance not yet rendered returned => process
          stepInstanceId.current = data.stepInstanceId;

          let newStepInstance : any = null;
          if (data.type === 'BASIC_INPUT' || data.type === 'BASIC_ALERT' || data.type === 'BASIC_INPUT_EVSEID_SELECTOR') {
            newStepInstance = <Input key={stepInstanceId.current} clientInput={data.clientInput} isFinal={data.isFinal} isApp={isApp} config={data.config} salutation={salutation} stepInstanceId={stepInstanceId.current} languageTextsLocal={languageTextsLocal.current} handler={clientOutputHandler}/>;
            waitingForClientOutput.current = data.isStepInstanceClientOutputFinal ? false : true;
          
          } else if (data.type === 'ACTION_ASYNC') {
            newStepInstance = <Loader key={stepInstanceId.current} clientInput={data.clientInput} maxPollIntervallInMilliSecs={data.config.maxPollIntervallInMilliSecs}/>;

          } else if (data.type === 'BASIC_INPUT_VEHICLE_SELECTOR') {
            newStepInstance = <CarSelector key={stepInstanceId.current} clientInput={data.clientInput} stepInstanceId={stepInstanceId.current} languageTextsLocal={languageTextsLocal.current} handler={clientOutputHandler}/>;
            waitingForClientOutput.current = data.isStepInstanceClientOutputFinal ? false : true;
          
          } else if (data.type === 'BASIC_INPUT_ALTERNATIVE_STATION') {
            if (data.clientInput.stationEnBW) {
              newStepInstance = <OtherStation key={stepInstanceId.current} clientInput={data.clientInput} isApp={isApp} languageTextsLocal={languageTextsLocal.current}/>;
              waitingForClientOutput.current = data.isStepInstanceClientOutputFinal ? false : true;
            } else {
              newStepInstance = <Input 
                key={uuidv4()} 
                clientInput={{
                  text: [
                    { text : languageTextsLocal.current['noStationFound'], bold : true },
                    { text : languageTextsLocal.current['contactSupport'] },
                    { image : 'info' }
                  ],
                  inputs: [
                    {
                      inputText: languageTextsLocal.current['allOk'],
                      client : {
                        app : {
                          refresh : 1,
                          call : ENBW_SUPPORT_HOTLINE,
                        }
                      }
                    }
                  ]
                }} 
                isFinal={true} 
                config={{
                  client : {
                    app : {
                      showSupport : true
                    } 
                  }
                }} 
                salutation={''} 
                stepInstanceId={uuidv4()} 
                isApp={isApp}
                languageTextsLocal={languageTextsLocal.current}
                handler={clientOutputHandler}/>
            }
          }

          if (newStepInstance) {
            setActiveView(newStepInstance);
            setDisabled(false);
            setCurrentUUID(uuidv4());
          }

          if (data.isSessionFinal) {
            finalizeWithSuccess();
            return;
          }

          if (!waitingForClientOutput.current) {
            timeoutRef.current = setTimeout(() => {
              fetchServer();
            }, data.config && data.config.minViewTimeInMilliSecs ? data.config.minViewTimeInMilliSecs : MIN_VIEW_TIME_IN_MILLI_SECS);
          }

        // waiting for first stepInstanceId
        } else {
          if (data && data.isSessionFinal) {
            finalizeWithSuccess();
            return;
          }
          timeoutRef.current = setTimeout(() => {
            fetchServer();
          }, data.config && data.config.pollIntervalInMilliSecs ? data.config.pollIntervalInMilliSecs : POLL_INTERVAL_IN_MILLI_SECS);
        }
      })
      .catch((e) => {

        if (isFinal.current) {
          return;
        }

        if (e && e.name === 'BackendError') {
          finalizeWithError();

        } else {
          if (!poll_timestamp_server_start.current) {
            poll_timestamp_server_start.current = Date.now();
          } else if (poll_timestamp_server_start.current + 
            MAX_POLL_INTERVAL_IN_MILLI_SECS_SERVER_NOT_AVAILABLE > Date.now()) {
            finalizeWithError();
            return;
          }
          fetchServer();
        }     
      })
  };

  const Spinner = () => <div className="loader"></div>;

  // const callNumber = () => {
  //   window.open('tel:' + ENBW_SUPPORT_HOTLINE);
  // };

  // Code to make Buttons shown below of screen
  // https://jsfiddle.net/7hsuwbmv/3/

  return <main className="d-block d-sm-block d-md-none d-lg-none">
            {showHeaderRef.current &&
              <Box sx={{ marginBottom: '50px', display: 'flex', justifyContent: 'space-between' }}>
                <ToggleButtonGroup
                  value={language}
                  exclusive
                  onChange={handleLanguage}
                  aria-label="Sprache"
                  style={{zIndex:10000}}
                >
                  <ToggleButton value="de" aria-label="deutsch" size="small">
                    Deutsch
                  </ToggleButton>
                  <ToggleButton value="en" aria-label="englisch" size="small">
                    Englisch
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button 
                  variant="outlined" 
                  size="small"
                  onClick={() => { 
                  if (!isFinal.current) {
                    showCancelModalRef?.current?.open();
                  } else {
                    setTimeout(() => { alert(languageTextsLocal.current['closeBrowser']); }, 1000);
                  }
                }}>
                  Abbrechen
                </Button>
                <CancelForm ref={showCancelModalRef} handler={cancelModalHandler}/>
              </Box>
            }
            {(isInitializing || !isLoaded) && <Spinner/>}
            {!isInitializing && isLoaded &&
              <TransitionGroup className="slide-group">
                <CSSTransition
                  classNames="slide"
                  timeout={{ enter: 200, exit: 0 }}
                  key={currentUUID}
                >
                  <div style={{width:'100%'}}>
                    {activeView}
                 </div>
               </CSSTransition>
              </TransitionGroup>
            }
        </main>
}

export default Bot;
