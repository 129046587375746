import { BrowserRouter as Router } from "react-router-dom";

import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

import PageLayout from "./components/PageLayout";
import Bot from "./Bot";

export interface AppProps {
  instance: PublicClientApplication;
  env: any;
};

export const getToken = (instance : any, account : any, scopes : any) : Promise<string> => {
  return new Promise(async (resolve, reject) => {
    let response;

    try {
      response = await instance.acquireTokenSilent({
        account: account,
        scopes: scopes
      });

      if (response.accessToken) {
        resolve(response.accessToken);
      } else {
        reject(Error('Failed to acquire an access token'));
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect({
          scopes: scopes
        });
      }
    }
  });
}

const App = ({ instance, env } : AppProps) => {

  const search = window.location.search;
  const headerless : string | null = new URLSearchParams(search).get('headerless');
  const inner : string | null = new URLSearchParams(search).get('inner');

  const app : boolean = !!(new URLSearchParams(search).get('app'));

  const accessToken : string | null = new URLSearchParams(search).get('accessToken');
  const installationIdentifier : string | null = new URLSearchParams(search).get('installationIdentifier');
  const advertisingIdentifier : string | null = new URLSearchParams(search).get('advertisingIdentifier');
  const identifierForVendor : string | null = new URLSearchParams(search).get('identifierForVendor');
  const platform : string | null = new URLSearchParams(search).get('platform');
  const appVersion : string | null = new URLSearchParams(search).get('appVersion');
  const osVersion : string | null = new URLSearchParams(search).get('osVersion');
  const versionCode : string | null = new URLSearchParams(search).get('versionCode');
  const deviceModel : string | null = new URLSearchParams(search).get('deviceModel');
  const messagingToken : string | null = new URLSearchParams(search).get('messagingToken');
  const apiKey : string | null = new URLSearchParams(search).get('apiKey');

  const evseId : string | null = new URLSearchParams(search).get('evseId');
  const sessionId : string | null = new URLSearchParams(search).get('sessionId');
  const stepInstanceId : string | null = new URLSearchParams(search).get('stepInstanceId');

  const language : string | null = new URLSearchParams(search).get('language');
  const carId : string | null = new URLSearchParams(search).get('carId');

  return (
    <Router>
      <MsalProvider instance={instance}>
        <PageLayout 
            headerless={headerless ? headerless.toLowerCase() === 'true' : app} 
            inner={inner ? inner.toLowerCase() === 'true' : app}
            evseId={evseId} 
            sessionId={sessionId}
            language={language}
            skipAuthorize={app}
            env={env}>
          <Bot 
            env={env}
            accessToken={accessToken}
            installationIdentifier={installationIdentifier}
            advertisingIdentifier={advertisingIdentifier}
            identifierForVendor={identifierForVendor}
            platform={platform}
            appVersion={appVersion}
            osVersion={osVersion}
            versionCode={versionCode}
            deviceModel={deviceModel}
            messagingToken={messagingToken}
            apiKey={apiKey}
            evseId={evseId} 
            sessionId={sessionId}
            stepInstanceId={stepInstanceId}
            language={language}
            carId={carId}
            showHeader={!app}
            isApp={app}
          />
        </PageLayout>
      </MsalProvider>
    </Router>
  );
}

export default App;
