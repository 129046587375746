import { useState, useEffect } from 'react';

import { Navbar, Button } from "react-bootstrap";

import { useMsal, useAccount } from "@azure/msal-react";
import { getToken } from '../App';

export const NavigationBar = ({env} : {env:any}) => {

    const SERVER_ENDPOINT : string = env?.API_URL ?? 'http://localhost:8080/bot/session';

    const [isAppDisabled, setIsAppDisabled] = useState<string | null>(null);

    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
      if (account && inProgress === "none") {
        getToken(instance, account, ["User.Read"]).then((accessToken : string) => {
          setAccessToken(accessToken)
        }).catch((error) => console.log(error));
      }
    }, [account, inProgress, instance]);

    useEffect(() => {
      setIsAdmin(false);
      if (accessToken) {
        fetch(SERVER_ENDPOINT.replace('bot/session', 'settings'), {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'x-api-key' : accessToken },
        })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          return;
        })
        .then((data) => {
          if (data?.disabled !== undefined) {
            setIsAppDisabled(data.disabled);
          } else {
            setIsAppDisabled(null);
          }
        });

        fetch('https://graph.microsoft.com/v1.0/me/getMemberGroups', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' +  accessToken},
          body: JSON.stringify({ securityEnabledOnly : false }),
        })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          return;
        })
        .then((data) => {
          if (data?.value) {
            setIsAdmin(data?.value.includes('d3ada0de-41d7-42e8-9427-35d8e7da6247'));
          }
        });
      }
    }, [accessToken]);

    const onClickDisabled = () => {
      if (accessToken) {
        fetch(SERVER_ENDPOINT.replace('bot/session', 'settings'), {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'x-api-key' : accessToken },
          body: JSON.stringify({
            disabled : !isAppDisabled,
          })
        })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
          return;
        })
        .then((data) => {
          if (data?.disabled !== undefined) {
            setIsAppDisabled(data.disabled);
          } else {
            setIsAppDisabled(null);
          }
        });
      }
    };

    return <>
          <Navbar className="color-nav" variant="dark">
            <img src="./logo_enbw_dark.png" style={{ float:'left', paddingLeft:'10px', paddingRight:'20px', paddingTop:'0px' }} alt=''/>
            <a className="navbar-brand" href="/">eMob Soforthilfe v2.0.38</a>
            <Button style={{ marginLeft:'10px' }} onClick={() => window.open('https://support-faqbot.emob-enbw.com/')}>FAQ-Bot<br/>aufrufen</Button>
            <Button style={{ marginLeft:'30px', backgroundColor : '#fd951f', borderColor: '#fff' }} onClick={() => window.open('https://enbw.visualstudio.com/eMob-Betrieb/_workitems/create/DigiBetrieb?templateId=02ecf1e4-a99c-4c4e-8698-ee2b40412a3a&ownerId=fea62bcd-174d-49a7-8c3c-2fab042d2637')}>Bot Feedback oder<br/>Bot Fehler melden</Button>
            {isAppDisabled !== null && isAdmin &&
              <Button className="ml-auto" style={{ backgroundColor: '#fd951f', borderColor: '#fff' }} onClick={onClickDisabled}>{isAppDisabled ? 'mobility+ App aktivieren' : 'mobility+ App deaktivieren'}</Button>
            }
            <Button className="ml-auto" style={{ backgroundColor: '#fd951f', borderColor: '#fff' }} onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}>Sign out</Button>
          </Navbar>
        </>;
};
