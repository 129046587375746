import {useState} from 'react';
import { TextField, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';

import { useRecoilValue } from 'recoil';
import { disabledAtom } from '../Bot';

import * as _ from "lodash";

interface CarSelectorProps {
    clientInput: any;
    stepInstanceId: null | string;
    languageTextsLocal: Record<string, string>;
    handler: Function;
}

const CarSelector = ({ clientInput, stepInstanceId, languageTextsLocal, handler } : CarSelectorProps) => {

  const disabled = useRecoilValue<boolean>(disabledAtom);

  const [brand, setBrand] = useState<any | null>(null);
  const [model, setModel] = useState<any | null>(null);

  return <div id="wrap">
          <div className="wrapper">
            <div className="main_center" style={{marginBottom:'5px'}}>
              <div className="middle">
                <h2 className="main-title" style={{marginBottom:'25px'}}>{languageTextsLocal['carSelector1']}</h2>
                <p className="text-gray" style={{marginBottom:0}}>{languageTextsLocal['carSelector2']}</p>
              </div>
            </div>

            <form className="form mt-4 mb-4">
              <div className="mb-3"> 
                <label className="label-top" style={{}}>{languageTextsLocal['brand']}</label>
                <Autocomplete
                  key={'brand'}
                  options={clientInput.vehicles}
                  value={brand}
                  getOptionLabel={(option) => option.brand}
                  disablePortal={true}
                  blurOnSelect
                  renderInput={(params) => {
                    return (<TextField {...params} 
                      sx={{
                        '& .MuiAutocomplete-endAdornment': {top: 'calc(50% - 12px)'},
                      }} 
                      autoFocus={true}
                      variant="outlined" 
                      color="primary" 
                      inputProps={{ ...params.inputProps, 
                        style: { fontFamily: 'SFProText', fontSize: 16, fontWeight: 400, lineHeight: '24px', paddingRight: '16px!important' } 
                      }}
                      disabled={disabled}/>
                    );
                  }}
                  onChange={(_event: any, newValue: any | null) => {
                    setBrand(newValue);
                    setModel(null);
                  }}
                  onOpen={() => {
                    setBrand(null);
                    setModel(null);
                  }}
                  renderOption={(props : any, option: any) => (
                    <Typography {...props} style={{ fontFamily: 'SFProText', fontSize: 16, fontWeight: 400, lineHeight: '24px' }}>{option.brand}</Typography>
                  )}
                />
              </div>
              <div className="mt-4"> 
                <label className="label-top">{languageTextsLocal['model']}</label>
                <Autocomplete
                  key={'model'}
                  options={brand ? brand.models : []}
                  getOptionLabel={(option) => option.model}
                  disablePortal={true}
                  value={model}
                  blurOnSelect                  
                  renderInput={(params) => {
                    return (<TextField {...params} 
                      sx={{
                        '& .MuiAutocomplete-endAdornment': {top: 'calc(50% - 12px)'},
                      }} 
                      inputRef={input => input && !_.isNil(brand) && _.isNil(model) && input.focus()}
                      variant="outlined" 
                      color="primary" 
                      inputProps={{ ...params.inputProps, style: { fontFamily: 'SFProText', fontSize: 16, fontWeight: 400, lineHeight: '24px' } }}
                      disabled={disabled}/>
                    );
                  }}
                  onChange={(_event: any, newValue: any | null) => {
                    setModel(newValue);
                  }}
                  renderOption={(props : any, option: any) => (
                    <Typography {...props} style={{ fontFamily: 'SFProText', fontSize: 16, fontWeight: 400, lineHeight: '24px' }}>{option.model}</Typography>
                  )}
                />
              </div>
            </form>

            {(!model || !model.editions || model.editions.length === 0 || !handler) &&
              <div className="main_center" style={{marginTop:0, marginBottom:0}}>
                <div className="middle">
                  <p className="text-gray-small">{languageTextsLocal['carSelector3']}</p>
                </div>
              </div>
            } 

            <div className="e-auto">
              {model && model.editions && model.editions.length > 0 && handler && model.editions.map((t : any, i : number, _arr : any) => {
                return <div key={i} className="media align-items-center" style={{cursor:'pointer', opacity: disabled ? '0.2' : '1'}} onClick={() => { if (!disabled) { handler(stepInstanceId, { 'inputValue' : '0', 'vehicle' : { 'carId': t.id, 'brand' : brand.brand, 'model' : model.model, 'edition' : t.name }})}}}>
                  <img className="mr-3" style={{width:'150px'}} src={t.thumbnailUrl} alt=""/>
                  <div className="media-body">
                    <span className="text-gray d-block fs-14">{brand.brand}</span>
                    <p className="mb-0 fw-700">{t.name}</p>
                    <span className="text-gray fs-14">{languageTextsLocal['capacity'] + ': ' + t.batteryCapacityInKwh + ' kWh (' + t.range + ' km)'}</span>
                  </div>
                 </div>
              })} 
            </div>
          </div>
        </div>
};

export default CarSelector;
